

$fa-font-path:"/fonts/vendor/font-awesome/";

/* colors */
$theme-color:#134a98;
$theme-light-color:#134a98;
$theme-dark-color:#005aab;
$orange-color:#F35D2B;
$white-color:#f9f9f9;
$blue-color: #0a4d9b;

$quicksand-font:'Quicksand', sans-serif;